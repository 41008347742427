<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการหัตถการ/คอร์ส</h4>
      </div>

      <div class="d-flex flex-row align-center justify-end">
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="mr-3 white--text"
          @click="addTimeLimit"
        >
          <v-icon
            left
            dark
          >
            mdi-plus-circle
          </v-icon>
          เพิ่มคอร์สแบบจำกัดครั้ง
        </v-btn>
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="mr-3 white--text"
        >
          <v-icon
            left
            dark
          >
            mdi-file-excel-outline
          </v-icon>
          นำเข้าข้อมูลแบบจำกัดครั้ง
        </v-btn>
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="mr-3 white--text"
          @click="addNoTimeLimit"
        >
          <v-icon
            left
            dark
          >
            mdi-plus-circle
          </v-icon>
          เพิ่มคอร์สแบบไม่จำกัดครั้ง
        </v-btn>
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="white--text"
        >
          <v-icon
            left
            dark
          >
            mdi-file-excel-outline
          </v-icon>
          นำเข้าข้อมูลแบบไม่จำกัดครั้ง
        </v-btn>
      </div>

      <v-card-title class="pt-3 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsCategory"
              v-model="selectCategory"
              item-text="name"
              item-value="value"
              label="หมวดหมู่"
              placeholder="เลือกหมวดหมู่"
              no-data-text="ยังไม่มีข้อมูล"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsType"
              v-model="selectType"
              item-text="name"
              item-value="value"
              label="ประเภท"
              placeholder="เลือกประเภท"
              no-data-text="ยังไม่มีข้อมูล"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="selectStatus"
              item-text="name"
              item-value="value"
              label="สถานะ"
              placeholder="เลือกสถานะ"
              no-data-text="ยังไม่มีข้อมูล"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.operativecourse_name="{ item }">
        <div class="text-start">{{item.operativecourse_name}}</div>
      </template>
      <template v-slot:item.operativecourse_code="{ item }">
        <div class="text-start">{{item.operativecourse_code}}</div>
      </template>
      <template v-slot:item.operativecourse_category="{ item }">
        <div class="text-start">{{item.operativecourse_category}}</div>
      </template>
      <template v-slot:item.operativecourse_costprice="{ item }">
        <div class="text-end">{{formatThaiBaht(item.operativecourse_costprice)}}</div>
      </template>
      <template v-slot:item.operativecourse_wagesdoctor="{ item }">
        <div :class="formatThaiBaht(item.operativecourse_wagesdoctor) ? 'text-end' : ''">{{formatThaiBaht(item.operativecourse_wagesdoctor) || '-'}}</div>
      </template>
      <template v-slot:item.operativecourse_wagesemployee="{ item }">
        <div :class="formatThaiBaht(item.operativecourse_wagesemployee) ? 'text-end' : ''">{{formatThaiBaht(item.operativecourse_wagesemployee) || 'ไม่ระบุ'}}</div>
      </template>
      <template v-slot:item.operativecourse_costdrug="{ item }">
        <div :class="formatThaiBaht(item.operativecourse_costdrug) ? 'text-end' : ''">{{formatThaiBaht(item.operativecourse_costdrug) || '-'}}</div>
      </template>
      <template v-slot:item.operativecourse_costsupplie="{ item }">
        <div :class="formatThaiBaht(item.operativecourse_costsupplie) ? 'text-end' : ''">{{formatThaiBaht(item.operativecourse_costsupplie) || '-'}}</div>
      </template>
      <template v-slot:item.operativecourse_amountcourse="{ item }">{{item.operativecourse_amountcourse || 'ไม่จำกัดจำนวนครั้ง'}}</template>
      <template v-slot:item.operativecourse_amountday="{ item }">{{item.operativecourse_amountday || '-'}}</template>
      <template v-slot:item.operativecourse_status="{ item }">
        <div class="d-flex justify-center align-center">
          <v-switch
            v-model="item.operativecourse_status_bool"
            readonly
            inset
            dense
          ></v-switch>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="viewItem(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editItem(item)"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="deleteItem(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title><v-icon class="mr-3">mdi-auto-fix</v-icon>{{boolViewItems ? dataDialog.operativecourse_name : boolEditItems ? `แก้ไขข้อมูลหัตถการ/คอร์ส ${dataDialog.operativecourse_name}` : 'ลบข้อมูลหมวดหมู่หัตถการ/คอร์ส'}}</v-card-title>
        <v-card-text v-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_status}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ราคาขาย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(dataDialog.operativecourse_costprice)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือแพทย์:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(dataDialog.operativecourse_wagesdoctor)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ค่ามือพนักงาน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{formatThaiBaht(dataDialog.operativecourse_wagesemployee)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนครั้ง:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_amountcourse || 'ไม่จำกัดจำนวนครั้ง'}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนวัน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_amountday || '-'}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รายละเอียดอื่นๆ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <span>{{dataDialog.operativecourse_detail || '-'}}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อหมวดหมู่หัตถการ/คอร์ส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.operativecourse_category"
                label="ชื่อหมวดหมู่หัตถการ/คอร์ส"
                placeholder="ระบุชื่อหมวดหมู่หัตถการ/คอร์ส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.operativecourse_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบประเภท ${dataDialog.operativecourse_category || ''} นี้ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">
            <v-icon v-if="!boolDelItems" class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            :color="boolViewItems ? 'blue darken-1' : 'success'"
            text
            @click="submitDialog"
            :disabled="!cansave"
          >
            <v-icon v-if="!boolViewItems && !boolDelItems" class="mr-3">mdi-content-save</v-icon>
            {{boolViewItems ? 'ปิด' : boolEditItems ? 'บันทึก' : 'ยืนยัน'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: [],
      itemsCategory: [],
      itemsType: [],
      itemsStatus: [{
        name: 'เปิดใช้งาน',
        value: true
      }, {
        name: 'ปิดใช้งาน',
        value: false
      }],
      selectCategory: '',
      selectType: '',
      selectStatus: ''
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'no'
        }, {
          text: 'ชื่อหัตถการ/คอร์ส',
          align: 'center',
          sortable: false,
          value: 'operativecourse_name'
        }, {
          text: 'รหัส',
          align: 'center',
          sortable: false,
          value: 'operativecourse_code'
        }, {
          text: 'หมวดหมู่',
          align: 'center',
          sortable: false,
          value: 'operativecourse_category'
        }, {
          text: 'ราคาคอร์ส',
          align: 'center',
          sortable: false,
          value: 'operativecourse_costprice'
        }, {
          text: 'ค่ามือแพทย์',
          align: 'center',
          sortable: false,
          value: 'operativecourse_wagesdoctor'
        }, {
          text: 'ค่ามือพนักงาน',
          align: 'center',
          sortable: false,
          value: 'operativecourse_wagesemployee'
        }, {
          text: 'ค่ายา',
          align: 'center',
          sortable: false,
          value: 'operativecourse_costdrug'
        }, {
          text: 'ค่าอุปกรณ์',
          align: 'center',
          sortable: false,
          value: 'operativecourse_costsupplie'
        }, {
          text: 'ประเภทคอร์ส',
          align: 'center',
          sortable: false,
          value: 'operativecourse_type'
        }, {
          text: 'จำนวนครั้ง',
          align: 'center',
          sortable: false,
          value: 'operativecourse_amountcourse'
        }, {
          text: 'จำนวนวัน',
          align: 'center',
          sortable: false,
          value: 'operativecourse_amountday'
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'operativecourse_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  watch: {
    selectStatus () {
      const self = this
      self.loaddata = true
      var temp = []
      self.data.forEach((element) => {
        if (element.operativecourse_status_bool === self.selectStatus) {
          temp.push(element)
        }
      })
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 500)
    }
  },
  methods: {
    initData () {
      const self = this
      self.fetchData()
    },
    async fetchData () {
      const self = this
      var temp = []
      self.loaddata = true
      self.selectCategory = ''
      self.selectType = ''
      self.selectStatus = ''
      temp.push({
        _id: '000001',
        no: 1,
        operativecourse_name: 'Botox SET A',
        operativecourse_code: 'BT0001',
        operativecourse_category: 'Botox',
        operativecourse_costprice: 4999,
        operativecourse_wagesdoctor: 500,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 50,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'ไม่จำกัดครั้ง',
        operativecourse_amountcourse: 0,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000002',
        no: 2,
        operativecourse_name: 'Botox SET B',
        operativecourse_code: 'BT0002',
        operativecourse_category: 'Botox',
        operativecourse_costprice: 4999,
        operativecourse_wagesdoctor: 500,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 50,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'ไม่จำกัดครั้ง',
        operativecourse_amountcourse: 0,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000003',
        no: 3,
        operativecourse_name: 'Aura bright 2 แถม 1',
        operativecourse_code: 'VT0001',
        operativecourse_category: 'วิตามินผิว',
        operativecourse_costprice: 1999,
        operativecourse_wagesdoctor: 250,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 0,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'จำกัดครั้ง',
        operativecourse_amountcourse: 1,
        operativecourse_amountday: 0,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000004',
        no: 4,
        operativecourse_name: 'Ultra shine 2 แถม 1',
        operativecourse_code: 'VT0002',
        operativecourse_category: 'วิตามินผิว',
        operativecourse_costprice: 2999,
        operativecourse_wagesdoctor: 250,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 0,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'จำกัดครั้ง',
        operativecourse_amountcourse: 1,
        operativecourse_amountday: 0,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000005',
        no: 5,
        operativecourse_name: 'Magic white 2 แถม 1',
        operativecourse_code: 'VT0003',
        operativecourse_category: 'วิตามินผิว',
        operativecourse_costprice: 3999,
        operativecourse_wagesdoctor: 250,
        operativecourse_wagesemployee: 0,
        operativecourse_costdrug: 0,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'จำกัดครั้ง',
        operativecourse_amountcourse: 1,
        operativecourse_amountday: 0,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000006',
        no: 6,
        operativecourse_name: 'Botox SET เดียวเรียวจุกๆ',
        operativecourse_code: 'BTS0001',
        operativecourse_category: 'หน้าเรียว',
        operativecourse_costprice: 7999,
        operativecourse_wagesdoctor: 1000,
        operativecourse_wagesemployee: 100,
        operativecourse_costdrug: 100,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'ไม่จำกัดครั้ง',
        operativecourse_amountcourse: 0,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000007',
        no: 7,
        operativecourse_name: 'Botox Pack A',
        operativecourse_code: 'BT0003',
        operativecourse_category: 'ลดริ้วรอย',
        operativecourse_costprice: 2999,
        operativecourse_wagesdoctor: 500,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 50,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'ไม่จำกัดครั้ง',
        operativecourse_amountcourse: 0,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000008',
        no: 8,
        operativecourse_name: 'Botox Pack B',
        operativecourse_code: 'BT0004',
        operativecourse_category: 'ลดริ้วรอย',
        operativecourse_costprice: 2999,
        operativecourse_wagesdoctor: 700,
        operativecourse_wagesemployee: 70,
        operativecourse_costdrug: 50,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'ไม่จำกัดครั้ง',
        operativecourse_amountcourse: 0,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000009',
        no: 9,
        operativecourse_name: 'DermaScar รักษาหลุมสิว 5 ครั้ง 9999',
        operativecourse_code: 'VT0004',
        operativecourse_category: 'รักษาหลุมสิว',
        operativecourse_costprice: 2500,
        operativecourse_wagesdoctor: 500,
        operativecourse_wagesemployee: 50,
        operativecourse_costdrug: 50,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'จำกัดครั้ง',
        operativecourse_amountcourse: 5,
        operativecourse_amountday: 0,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000010',
        no: 10,
        operativecourse_name: 'Botox ลิฟต์กรอบหน้า',
        operativecourse_code: 'VS0001',
        operativecourse_category: 'Botox',
        operativecourse_costprice: 3999,
        operativecourse_wagesdoctor: 1000,
        operativecourse_wagesemployee: 100,
        operativecourse_costdrug: 100,
        operativecourse_costsupplie: 0,
        operativecourse_type: 'จำกัดครั้ง',
        operativecourse_amountcourse: 1,
        operativecourse_amountday: 60,
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      })
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 500)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    formatThaiBaht (num) {
      if (num) {
        var p = num.toFixed(2).split('.')
        return p[0].split('').reverse().reduce((acc, num, i, orig) => {
          return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
        }, '') + '.' + p[1]
      } else return ''
    },
    addTimeLimit () {
      const self = this
      self.loader = true
      setTimeout(() => {
        self.loader = null
        self.$router.push({ name: 'CreateListOperativeAndCourseScreen', params: { addType: 'จำกัดจำนวนครั้ง' } })
      }, 200)
    },
    addNoTimeLimit () {
      const self = this
      self.loader = true
      setTimeout(() => {
        self.loader = null
        self.$router.push({ name: 'CreateListOperativeAndCourseScreen', params: { addType: 'ไม่จำกัดจำนวนครั้ง' } })
      }, 200)
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.loader = true
      setTimeout(() => {
        self.loader = null
        self.$router.push({ name: 'UpdateListOperativeAndCourseScreen', params: { dataItem: item } })
      }, 200)
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.initData()
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      if (self.boolEditItems) {
        if (self.dataDialog.operativecourse_category) {
          self.closeDialog()
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลหมวดหมู่หัตถการ/คอร์สให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        self.closeDialog()
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
